import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Skeleton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import "primereact/resources/themes/md-light-indigo/theme.css"; // PrimeReact theme
import "primereact/resources/primereact.min.css"; // PrimeReact core styles
import "../../styles/MegaMenu.css"; // Custom menu styles

// URL for fetching category data
const CATEGORIES_URL = "https://productfeed.fashion-editor.gr/product-feed/integration/categories.json";

const HeaderNavigation = ({ isMobile = false, onClose }) => {
  const [categories, setCategories] = useState([]); // State to store fetched categories
  const [isLoading, setIsLoading] = useState(true); // Loading state for categories

  // Fetch categories from the remote URL
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(CATEGORIES_URL);
        const data = await response.json();
        setCategories(data || []); // Ensure categories are set or fallback to an empty array
        setIsLoading(false); // Stop loading after data is set
      } catch (error) {
        console.error("Error fetching categories:", error);
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Build the desktop menu bar items
  const buildMenuBarItems = () => {
    return categories
      .filter((parentCategory) => parentCategory.parent === 0) // Top-level categories
      .map((parentCategory) => {
        const childItems = categories
          .filter((childCategory) => childCategory.parent === parentCategory.id)
          .map((childCategory) => ({
            label: childCategory.name,
            items: categories
              .filter((grandChild) => grandChild.parent === childCategory.id)
              .map((grandChild) => ({
                label: grandChild.name,
                command: () => {
                  window.location.href = `/product-category/${grandChild.slug}`;
                },
              })),
          }));

        return {
          label: parentCategory.name,
          items: childItems.length > 0 ? childItems : [{ label: "No items available" }],
        };
      });
  };

  // Build the mobile accordion menu
  const buildMobileMenuItems = () => {
    return categories
      .filter((parentCategory) => parentCategory.parent === 0) // Top-level categories
      .map((parentCategory) => (
        <AccordionItem key={parentCategory.id}>
          <AccordionButton _hover={{ bg: "gray.300", color: "black" }}>
            <Box flex="1" textAlign="left">
              {parentCategory.name.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {categories
              .filter((childCategory) => childCategory.parent === parentCategory.id)
              .map((childCategory) => (
                <AccordionItem key={childCategory.id}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {childCategory.name.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {categories
                      .filter((grandChild) => grandChild.parent === childCategory.id)
                      .map((grandChild) => (
                        <Text
                          key={grandChild.id}
                          as={RouterLink}
                          to={`/product-category/${grandChild.slug}`}
                          display="block"
                          pl={6} // Add padding for grandchild categories
                          onClick={onClose} // Trigger onClose when grandchild is selected
                        >
                          {grandChild.name.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
                        </Text>
                      ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </AccordionPanel>
        </AccordionItem>
      ));
  };

  return (
    <Flex
      as="nav"
      direction={isMobile ? "column" : "row"}
      bg={isMobile ? "pink.700" : "transparent"}
      color={isMobile ? "white" : "inherit"}
      padding="10px"
      justifyContent={isMobile ? "flex-start" : "space-around"}
      position="relative"
    >
      {isLoading ? (
        // Skeleton loaders for loading state
        [...Array(4)].map((_, index) => (
          <Skeleton key={index} height="20px" width="100px" mr="5" />
        ))
      ) : (
        <Box width="100%">
          {/* Render Menubar for desktop */}
          {!isMobile && <Menubar model={buildMenuBarItems()} />}

          {/* Render Accordion Menu for mobile */}
          {isMobile && (
            <Accordion allowMultiple>{buildMobileMenuItems()}</Accordion>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default HeaderNavigation;
