import React, { useEffect, useState, useMemo } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import HeroBanner from '../common/HeroBanner';
import FeaturedProductCarousel from '../common/FeaturedProductCarousel';
import TopCategoriesCarousel from '../common/TopCategoriesCarousel';
import BannerCarousel from '../common/BannerCarousel';
import { Helmet } from 'react-helmet-async';

// URLs for remote data
const CATEGORIES_URL = 'https://productfeed.fashion-editor.gr/product-feed/integration/categories.json';
const PRODUCTS_URL = 'https://productfeed.fashion-editor.gr/product-feed/integration/products_data.json';

const HomePage = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [menProducts, setMenProducts] = useState([]);
  const [womenProducts, setWomenProducts] = useState([]);
  const [kidsProducts, setKidsProducts] = useState([]);
  const [beautyProducts, setBeautyProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch data from remote URLs
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch products data
        const productsResponse = await fetch(PRODUCTS_URL);
        const products = await productsResponse.json();

        // Fetch categories data
        const categoriesResponse = await fetch(CATEGORIES_URL);
        const categories = await categoriesResponse.json();

        // Filter featured products
        const featured = products.filter(
          (product) => product.taxonomies?.featured && product.taxonomies.featured === true
        );
        setFeaturedProducts(featured);

        // Filter products for specific categories
        const men = products
          .filter((product) =>
            ['slim-fit', 'sneakers-papoutsia-andras', 't-shirts-me-typoma-t-shirts-roucha'].some(
              (slug) => product.taxonomies.product_cat_slug.includes(slug)
            )
          )
          .slice(0, 12);
        setMenProducts(men);

        const women = products
          .filter((product) =>
            ['maxi-dresses', 'platforms'].some((slug) =>
              product.taxonomies.product_cat_slug.includes(slug)
            )
          )
          .slice(0, 12);
        setWomenProducts(women);

        const kids = products
          .filter((product) =>
            ['formes', 'kolan-roucha-koritsi', 'boufan-panoforia-roucha-agori'].some((slug) =>
              product.taxonomies.product_cat_slug.includes(slug)
            )
          )
          .slice(0, 12);
        setKidsProducts(kids);

        const beauty = products
          .filter((product) =>
            ['make-up-foundations-prosopou', 'eau-de-toilette-unisex-aromata', 'maskara-mation'].some(
              (slug) => product.taxonomies.product_cat_slug.includes(slug)
            )
          )
          .slice(0, 12);
        setBeautyProducts(beauty);

        // Filter top-level categories
        const topCategories = categories.filter((category) => category.parent === 0);
        setCategories(topCategories);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Generate JSON-LD for Schema.org
  const generateJsonLd = useMemo(() => {
    const productsLd = featuredProducts.map((product) => ({
      '@type': 'Product',
      name: product.name,
      image: product.images?.original?.url || '',
      description: product.post_excerpt || '',
      sku: product.sku || '',
      offers: {
        '@type': 'Offer',
        priceCurrency: 'EUR',
        price: product.price,
        availability: 'https://schema.org/InStock',
        url: `${window.location.origin}/product/${product.slug}`,
      },
    }));

    const categoriesLd = categories.map((category) => ({
      '@type': 'Category',
      name: category.name,
      url: `${window.location.origin}/product-category/${category.slug}`,
      image: category.image || '',
    }));

    return {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'Fashion-editor.gr',
      description:
        'Welcome to the homepage of Fashion-editor.gr where you can find featured products and top categories.',
      url: 'https://fashion-editor.gr',
      potentialAction: {
        '@type': 'SearchAction',
        target: `${window.location.origin}/search?query={search_term_string}`,
        'query-input': 'required name=search_term_string',
      },
      mainEntity: [...productsLd, ...categoriesLd],
    };
  }, [featuredProducts, categories]);

  return (
    <Box>
      <Helmet>
        <title>
          Ανδρικά, Γυναικεία & Παιδικά Ρούχα | Παπούτσια & Αξεσουάρ Online - Fashion-editor.gr
        </title>
        <meta
          name="description"
          content="Ανακαλύψτε τις τελευταίες τάσεις στα Ανδρικά, Γυναικεία και Παιδικά Ρούχα, Παπούτσια και Αξεσουάρ Online!"
        />
        {/* JSON-LD for Schema.org */} 
        <script type="application/ld+json">{JSON.stringify(generateJsonLd)}</script>
      </Helmet>
      <HeroBanner />
      <Box p="2" maxW={{ base: '100%', md: '1320px' }} mx="auto">
        <Heading size="lg" mt="8" mb="8" textAlign="center">
          Νέες Αφίξεις
        </Heading>
        <FeaturedProductCarousel products={featuredProducts} isLoading={isLoading} />
        <Heading size="lg" mt="8" mb="8" textAlign="center">
          Προτάσεις για τον Άνδρα
        </Heading>
        <FeaturedProductCarousel products={menProducts} isLoading={isLoading} />
        <Heading size="lg" mt="8" mb="8" textAlign="center">
          Προτάσεις για την Γυναίκα
        </Heading>
        <FeaturedProductCarousel products={womenProducts} isLoading={isLoading} />
        <Heading size="lg" mt="8" mb="8" textAlign="center">
          Προτάσεις για το Παιδί
        </Heading>
        <FeaturedProductCarousel products={kidsProducts} isLoading={isLoading} />
        <Heading size="lg" mt="8" mb="8" textAlign="center">
          Προτάσεις Ομορφιάς
        </Heading>
        <FeaturedProductCarousel products={beautyProducts} isLoading={isLoading} />
        <Heading size="lg" mt="8" mb="8" textAlign="center">
          Περισσότερες Επιλογές
        </Heading>
        <TopCategoriesCarousel categories={categories} isLoading={isLoading} />
        <Heading size="lg" mt="8" mb="8" textAlign="center">
          Προτάσεις Συνεργατών
        </Heading>
        <BannerCarousel />
      </Box>
    </Box>
  );
};

export default HomePage;
