import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Input,
  Checkbox,
  Box,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  Tag,
  TagLabel,
  TagCloseButton,
  Button,
  Image,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  SkeletonText,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  Accordion,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Pagination from "../common/Pagination";
import SortAndViewControls from "../common/SortAndViewControls";

// URLs for remote data
const PRODUCTS_URL = "https://productfeed.fashion-editor.gr/product-feed/integration/products_data.json";

const DEFAULT_PAGE_SIZE = 20;

//Function scrollToTop
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const BrandsPage = () => {
  const { brandSlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [productsData, setProductsData] = useState([]);
  // const [categoriesData, setCategoriesData] = useState([]);

  // Fetch remote data for categories and products
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch categories
        // const categoriesResponse = await fetch(CATEGORIES_URL);
        // const categories = await categoriesResponse.json();
        // setCategoriesData(categories);

        // Fetch products
        const productsResponse = await fetch(PRODUCTS_URL);
        const products = await productsResponse.json();
        setProductsData(products);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }

      scrollToTop();
    };

    fetchData();
  }, []);

  const [pageSize, setPageSize] = useState(
    parseInt(searchParams.get("view")) || DEFAULT_PAGE_SIZE
  );
  const [sortOrder, setSortOrder] = useState(searchParams.get("sort") || "default");

  const brandFilter = searchParams.getAll("pa_brand");
  const colorFilter = searchParams.getAll("pa_color");
  const sizeFilter = searchParams.getAll("pa_size");
  const productCatFilter = searchParams.getAll("product_cat");
  const priceRange = useMemo(() => {
    return searchParams.get("price")?.split("-") || ["", ""];
  }, [searchParams]);

  const [brandSearch, setBrandSearch] = useState("");
  const [colorSearch, setColorSearch] = useState("");
  const [sizeSearch, setSizeSearch] = useState("");
  const [productCatSearch, setProductCatSearch] = useState("");

  // const breadcrumb = useMemo(() => buildBreadcrumb(categorySlug, categoriesData), [
  //   categorySlug,
  //   categoriesData,
  // ]);

  // const allCategorySlugs = useMemo(
  //   () => getCategoryAndSubcategories(categorySlug, categoriesData),
  //   [categorySlug, categoriesData]
  // );

  // const categoryProducts = useMemo(
  //   () =>
  //     productsData.filter((product) =>
  //       allCategorySlugs.includes(product.taxonomies.product_cat_slug)
  //     ),
  //   [allCategorySlugs, productsData]
  // );

  const brandProducts = useMemo(() => {
    return productsData.filter((product) => product.taxonomies.pa_brand?.includes(brandSlug));
  }, [brandSlug, productsData]);

  const availableBrands = useMemo(
    () => [...new Set(brandProducts.flatMap((product) => product.taxonomies.pa_brand || []))],
    [brandProducts]
  );

  const availableColors = useMemo(
    () => [...new Set(brandProducts.flatMap((product) => product.taxonomies.pa_color || []))],
    [brandProducts]
  );

  const availableSizes = useMemo(
    () => [...new Set(brandProducts.flatMap((product) => product.taxonomies.pa_size || []))],
    [brandProducts]
  );

  const availableProductCats = useMemo(
    () => [...new Set(brandProducts.flatMap((product) => product.taxonomies.product_cat || []))],
    [brandProducts]
  );

  const [minPrice, maxPrice] = useMemo(() => {
    const prices = brandProducts.map((product) => parseFloat(product.price)).filter(Boolean);
    return [Math.min(...prices), Math.max(...prices)];
  }, [brandProducts]);

  const filteredProducts = useMemo(() => {
    let filtered = brandProducts.filter((product) => {
      const matchesBrand =
        brandFilter.length > 0
          ? product.taxonomies.pa_brand?.some((brand) => brandFilter.includes(brand))
          : true;
      const matchesColor =
        colorFilter.length > 0
          ? product.taxonomies.pa_color?.some((color) => colorFilter.includes(color))
          : true;
      const matchesSize =
        sizeFilter.length > 0
          ? product.taxonomies.pa_size?.some((size) => sizeFilter.includes(size))
          : true;
      const matchesProductCat =
        productCatFilter.length > 0
          ? product.taxonomies.product_cat?.some((cat) => productCatFilter.includes(cat))
          : true;
      const matchesPrice =
        product.price &&
        (!priceRange[0] || parseFloat(product.price) >= parseFloat(priceRange[0])) &&
        (!priceRange[1] || parseFloat(product.price) <= parseFloat(priceRange[1]));

      return matchesBrand && matchesColor && matchesSize && matchesProductCat && matchesPrice;
    });

    if (sortOrder === "price-asc") {
      filtered = filtered.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (sortOrder === "price-desc") {
      filtered = filtered.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }

    return filtered;
  }, [
    brandFilter,
    colorFilter,
    sizeFilter,
    productCatFilter,
    priceRange,
    sortOrder,
    brandProducts,
  ]);

  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1
  );

  const paginatedProducts = useMemo(
    () => filteredProducts.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    [filteredProducts, currentPage, pageSize]
  );

  const totalPages = Math.ceil(filteredProducts.length / pageSize);

  const handleProductCatChange = (category) => {
    updateSearchParam("product_cat", category, true);
  };

  const updateSearchParam = (key, value, append = false) => {
    const newParams = new URLSearchParams(searchParams);
    const existingValues = newParams.getAll(key);

    if (key === "" && value === "") {
      newParams.delete("pa_brand");
      newParams.delete("pa_color");
      newParams.delete("pa_size");
      newParams.delete("product_cat");
      newParams.delete("price");
    } else if (append) {
      if (!existingValues.includes(value)) {
        newParams.append(key, value);
      } else {
        const updatedValues = existingValues.filter((v) => v !== value);
        newParams.delete(key);
        updatedValues.forEach((v) => newParams.append(key, v));
      }
    } else if (value) {
      newParams.set(key, value);
    } else {
      newParams.delete(key);
    }

    setSearchParams(newParams);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateSearchParam("page", page);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
    updateSearchParam("sort", e.target.value);
  };

  const handleViewChange = (e) => {
    const newPageSize = parseInt(e.target.value);
    setPageSize(newPageSize);
    setCurrentPage(1);
    updateSearchParam("view", newPageSize);
    updateSearchParam("page", 1);
  };

  const [priceInput, setPriceInput] = useState([minPrice || "", maxPrice || ""]);

  const handleInputChange = (index, value) => {
    const updatedPriceInput = [...priceInput];
    updatedPriceInput[index] = value;
    setPriceInput(updatedPriceInput);
  };

  const handleApplyPriceFilter = () => {
    const [min, max] = priceInput;
    updateSearchParam("price", `${min}-${max}`);
  };

  const handleResetPrice = () => {
    setPriceInput(["", ""]);
    updateSearchParam("price", "");
  };

  const handleRemoveFilter = (key, value) => {
    updateSearchParam(key, value, true);
  };

  const handleResetAll = () => {
    setBrandSearch("");
    setColorSearch("");
    setSizeSearch("");
    setProductCatSearch("");
    setPriceInput(["", ""]);
    updateSearchParam("", "");
  };

  const hasActiveFilters =
    searchParams.getAll("pa_brand").length > 0 ||
    searchParams.getAll("pa_color").length > 0 ||
    searchParams.getAll("pa_size").length > 0 ||
    searchParams.getAll("product_cat").length > 0 ||
    searchParams.getAll("price").length > 0;

  const activeFilters = useMemo(() => {
    return {
      pa_brand: searchParams.getAll("pa_brand"),
      pa_color: searchParams.getAll("pa_color"),
      pa_size: searchParams.getAll("pa_size"),
      product_cat: searchParams.getAll("product_cat"),
      price: searchParams.get("price") ? [searchParams.get("price")] : [],
    };
  }, [searchParams]);

  const handleDrawerClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDrawerOpen = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const generateBreadcrumbJsonLd = () => {
    const itemListElement = {
      "@type": "ListItem",
      position: 1,
      name: brandSlug,
      item: `${window.location.origin}/brands/${brandSlug}`,
    };

    return (
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: itemListElement,
        })}
      </script>
    );
  };

  const generateWebsiteSearchJsonLd = () => {
    const searchJsonLd = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "@id": window.location.origin + "/#website",
      url: window.location.origin,
      name: "Fashion-editor.gr",
      description:
        "Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr",
      potentialAction: [
        {
          "@type": "SearchAction",
          target: {
            "@type": "EntryPoint",
            urlTemplate: window.location.origin + "/?s={search_term_string}",
          },
          "query-input": {
            "@type": "PropertyValueSpecification",
            valueRequired: true,
            valueName: "search_term_string",
          },
        },
      ],
      inLanguage: "el",
    };

    return (
      <script type="application/ld+json">{JSON.stringify(searchJsonLd)}</script>
    );
  };

  return (
    <Box p="3" maxW={{ base: "100%", md: "1320px" }} mx="auto" overflow="hidden">
      <Helmet>
        <title>{brandSlug ? `${brandSlug} - Fashion-editor.gr` : 'Loading... - Fashion-editor.gr'}</title>
        <meta name="description" content={brandSlug ? `Ανακαλύψτε τις τελευταίες τάσεις σε ${brandSlug.replace(/&amp;/g, '&').replace(/AMP;/g, '')}.` : 'Loading...'} />
      
        {generateBreadcrumbJsonLd()}
        {generateWebsiteSearchJsonLd()}
      </Helmet>

      {/* {isLoading ? (
        // Skeleton for Breadcrumb loading
        <Skeleton height="20px" mb="6" />
      ) : ( */}
        <Box overflowX="auto" whiteSpace="nowrap" mb="6">
          <Breadcrumb display="inline-block">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/">Αρχική</BreadcrumbLink>
            </BreadcrumbItem>
            
              <BreadcrumbItem key={brandSlug}>
                <BreadcrumbLink as={RouterLink} to={`/brands/${brandSlug}`}>
                  {brandSlug.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
                </BreadcrumbLink>
              </BreadcrumbItem>
            
          </Breadcrumb>
        </Box>
      {/* )} */}

      <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          mb={4}
        >
        {/* Heading on the Left */}
        <Text as="h1" fontSize="2xl" fontWeight="bold">
          {brandSlug.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
        </Text>
        {/* Active Filters Tags */}
        <Box overflowX="auto" whiteSpace="nowrap" mb={4}>
          <Flex justifyContent="flex-start" alignItems="center" gap={2}>
            {Object.entries(activeFilters).map(([key, values]) =>
              values.map((value) => (
                <Tag
                  key={`${key}-${value}`}
                  size={{ base: "auto", md: "md" }}
                  padding={2}
                  borderRadius="full"
                  variant="solid"
                  colorScheme="pink"
                  mr={2}
                >
                  <TagLabel>{value}
                    {/* {key === "price"
                      ? `Price: ${value}`
                      : `${key.replace("pa_", "").toUpperCase()}: ${value}`} */}
                  </TagLabel>
                  <TagCloseButton onClick={() => handleRemoveFilter(key, value)} />
                </Tag>
              ))
            )}
          </Flex>
        </Box>
        {/* Sorting and View Options */}
        <Box display={{ base: 'none', md: 'flex' }} >
          <SortAndViewControls
            sortOrder={sortOrder}
            handleSortChange={handleSortChange}
            pageSize={pageSize}
            handleViewChange={handleViewChange}
            filteredProducts={filteredProducts}            
          />
        </Box>        
      </Flex>


      {/* Filters Button for Mobile */}
      <Box position="fixed" bottom="0" left="0" right="0" display={{ base: 'flex', md: 'none' }} justifyContent="center" pb={4}>    
          <Button size='sm' shadow="0px 0px 5px 4px rgba(0, 0, 0, .3)" colorScheme="pink" onClick={handleDrawerOpen}>Φίλτρα</Button>    
      </Box>

      <Flex direction={['column', 'row']} gap={4}>

      {/* Sidebar Filters */}
      <Box p="6" borderWidth="1px" minW="200px" maxW="300px" borderRadius="lg" display={{ base: 'none', md: 'block' }}>
        <Box flex="1">
          <Heading mb={4} size='lg' fontSize='24px'>Φίλτρα</Heading>
          {hasActiveFilters && ( // Conditionally render the button
            <Button mb={4} variant='ghost' leftIcon={<DeleteIcon />} size="sm" onClick={handleResetAll}>
              Καθαρισμός Όλων
            </Button>
          )}

          {isLoading ? (
                  // Skeleton for Filters Loading
                  <>
                    <Skeleton height="50px" mb="4" />
                    <Skeleton height="50px" mb="4" />
                    <Skeleton height="50px" mb="4" />
                  </>
          ) : (
          <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
            {/* Product Categories */}
            {availableProductCats.length > 1 && (
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">Κατηγορία</Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  {availableProductCats.length > 5 && (
                    <Input
                      placeholder="Αναζήτηση..."
                      mb={4}
                      value={productCatSearch}
                      onChange={(e) => setProductCatSearch(e.target.value)}
                    />
                  )}
                  <Stack maxHeight="150px" overflowY="auto">
                    {availableProductCats
                      .filter((cat) => cat.toLowerCase().includes(productCatSearch.toLowerCase()))
                      .map((cat) => (
                        <Checkbox
                          key={cat}
                          name="product_cat"
                          value={cat}
                          isChecked={productCatFilter.includes(cat)}
                          onChange={(e) => handleProductCatChange(cat)}
                        >
                          {cat.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
                        </Checkbox>
                      ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            )}
            {/* Brand Filter */}
            {availableBrands.length > 0 && (
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">Μάρκα</Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {availableBrands.length > 5 && (
                    <Input
                      placeholder="Αναζήτηση..."
                      mb={4}
                      value={brandSearch}
                      onChange={(e) => setBrandSearch(e.target.value)}
                    />
                  )}
                  <Stack maxHeight="150px" overflowY="auto">
                    {isLoading ? (
                      [...Array(5)].map((_, index) => (
                        <Skeleton key={index} height="20px" />
                      ))
                    ) : (
                      [...brandFilter, ...availableBrands.filter((brand) => !brandFilter.includes(brand))]
                        .filter((brand) => brand.toLowerCase().includes(brandSearch.toLowerCase()))
                        .map((brand) => (
                          <Checkbox
                            key={brand}
                            name="brands"
                            value={brand}
                            isChecked={brandFilter.includes(brand)}
                            onChange={(e) => updateSearchParam("pa_brand", brand, true)}
                          >
                            {brand}
                          </Checkbox>
                        ))
                    )}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            )}
            {/* Color Filter */}
            {availableColors.length > 0 && (
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">Χρώμα</Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {availableColors.length > 5 && (
                    <Input
                      placeholder="Αναζήτηση..."
                      mb={4}
                      value={colorSearch}
                      onChange={(e) => setColorSearch(e.target.value)}
                    />
                  )}
                  <Stack maxHeight="150px" overflowY="auto">
                    {isLoading ? (
                      [...Array(5)].map((_, index) => (
                        <Skeleton key={index} height="20px" />
                      ))
                    ) : (
                      [...colorFilter, ...availableColors.filter((color) => !colorFilter.includes(color))]
                        .filter((color) => color.toLowerCase().includes(colorSearch.toLowerCase()))
                        .map((color) => (
                          <Checkbox
                            key={color}
                            name="colors"
                            value={color}
                            isChecked={colorFilter.includes(color)}
                            onChange={(e) => updateSearchParam("pa_color", color, true)}
                          >
                            {color}
                          </Checkbox>
                        ))
                    )}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            )}
            {/* Size Filter */}
            {availableSizes.length > 0 && (
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">Μέγεθος</Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {availableSizes.length > 5 && (
                    <Input
                      placeholder="Αναζήτηση..."
                      mb={4}
                      value={sizeSearch}
                      onChange={(e) => setSizeSearch(e.target.value)}
                    />
                  )}
                  <Stack maxHeight="150px" overflowY="auto">
                    {isLoading ? (
                      [...Array(5)].map((_, index) => (
                        <Skeleton key={index} height="20px" />
                      ))
                    ) : (
                      [...sizeFilter, ...availableSizes.filter((size) => !sizeFilter.includes(size))]
                        .filter((size) => size.toLowerCase().includes(sizeSearch.toLowerCase()))
                        .map((size) => (
                          <Checkbox
                            key={size}
                            name="sizes"
                            value={size}
                            isChecked={sizeFilter.includes(size)}
                            onChange={(e) => updateSearchParam("pa_size", size, true)}
                          >
                            {size}
                          </Checkbox>
                        ))
                    )}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            )}
            {/* Price Filter */}
            <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Τιμή
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p="1">
              <Box p="2">
                <Flex gap="1" alignItems="center">
                  <Input
                    size="sm"
                    width={24}
                    type="number"
                    placeholder={`Min (${minPrice || ""})`}
                    value={priceInput[0]} // Local state for min price
                    onChange={(e) => handleInputChange(0, e.target.value)}
                  />
                  <Text>-</Text>
                  <Input
                    size="sm"
                    width={24}
                    type="number"
                    placeholder={`Max (${maxPrice || ""})`}
                    value={priceInput[1]} // Local state for max price
                    onChange={(e) => handleInputChange(1, e.target.value)}
                  />
                </Flex>
    
                <Flex justifyContent="space-between" mt="4">
                  <Button size="sm" onClick={handleResetPrice}>
                    Reset
                  </Button>
                  <Button size="sm" colorScheme="pink" onClick={handleApplyPriceFilter}>
                    Apply
                  </Button>
                  
                </Flex>
              </Box>
            </AccordionPanel>
            </AccordionItem>
          </Accordion>
          )}
        </Box>
      </Box>


        {/* Main Content */}
        <Box flex="3">
          
          <SimpleGrid columns={[2, 3, 5]} spacing="2">
            {isLoading ? (
              // Skeleton for products loading
              [...Array(12)].map((_, i) => (
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden" key={i}>
                  <Skeleton height="200px" />
                  <Box p="6">
                    <SkeletonText mt="4" noOfLines={4} spacing="4" />
                  </Box>
                </Box>
              ))
            ) : (
              // Display products once loaded
              paginatedProducts.map((product) => (
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden" key={product.objectID}>
                  <ChakraLink as={RouterLink} to={`/product/${product.slug}`} flex="1">
                    <Image
                      src={product.images?.thumbnail?.url}
                      alt={product.post_title}
                      width="100%"
                      height="auto"
                      objectFit="cover"
                      loading="lazy"
                      _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s' }}
                    />
                  </ChakraLink>
                  <Box p="2">
                    <Stack spacing="3">
                    <Text fontSize="xs" textTransform="uppercase">
                      {product.taxonomies.product_cat?.map((cat) => cat.replace(/&amp;/g, '&').replace(/AMP;/g, ''))} {/* Replace underscores with spaces */}
                    </Text>
    
                      <ChakraLink as={RouterLink} to={`/product/${product.slug}`}>
                        <Text fontWeight="bold" fontSize="sm" noOfLines={2} h="3em" lineHeight="1.5em">{product.post_title}</Text>
                      </ChakraLink>
                      <Text fontSize="sm">
                          {parseFloat(product.sale_price) < parseFloat(product.regular_price) ? (
                            <>
                              <span style={{ textDecoration: 'line-through', color: 'red' }}>
                                {product.regular_price} €
                              </span>{' '}
                              <span>{product.sale_price} €</span>
                            </>
                          ) : (
                            <span>{product.regular_price} €</span>
                          )}
                      </Text>
                    </Stack>
                  </Box>
                </Box>
              ))
            )}
          </SimpleGrid>
          {/* Pagination */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Box>

      </Flex>

      {/* Drawer for Mobile Filters */}
      <Drawer isOpen={isOpen} placement="bottom" onClose={handleDrawerClose}>
        <DrawerOverlay />
        <DrawerContent height="95%">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Φίλτρα</DrawerHeader>
          <DrawerBody>
            {isLoading ? (
              // Skeleton for Mobile Filters Loading
              <>
                <Skeleton height="50px" mb="4" />
                <Skeleton height="50px" mb="4" />
                <Skeleton height="50px" mb="4" />
              </>
            ) : (

            

            <Accordion allowMultiple>
              <Box mb={2}>
                <SortAndViewControls
                  sortOrder={sortOrder}
                  handleSortChange={handleSortChange}
                  pageSize={pageSize}
                  handleViewChange={handleViewChange}
                  filteredProducts={filteredProducts}
                />
              </Box>

              {/* Product Categories */}
              {availableProductCats.length > 1 && (
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">Κατηγορία</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    {availableProductCats.length > 5 && (
                      <Input
                        placeholder="Αναζήτηση..."
                        mb={4}
                        value={productCatSearch}
                        onChange={(e) => setProductCatSearch(e.target.value)}
                      />
                    )}
                    <Stack maxHeight="150px" overflowY="auto">
                      {availableProductCats
                        .filter((cat) => cat.toLowerCase().includes(productCatSearch.toLowerCase()))
                        .map((cat) => (
                          <Checkbox
                            key={cat}
                            name="product_cat"
                            value={cat}
                            isChecked={productCatFilter.includes(cat)}
                            onChange={(e) => handleProductCatChange(cat)}
                          >
                            {cat.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
                          </Checkbox>
                        ))}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              )}
              {/* Brand Filter */}
              {availableBrands.length > 0 && (
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">Μάρκα</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {availableBrands.length > 5 && (
                      <Input
                        placeholder="Αναζήτηση..."
                        mb={4}
                        value={brandSearch}
                        onChange={(e) => setBrandSearch(e.target.value)}
                      />
                    )}
                    <Stack maxHeight="150px" overflowY="auto">
                      {isLoading ? (
                        [...Array(5)].map((_, index) => (
                          <Skeleton key={index} height="20px" />
                        ))
                      ) : (
                        [...brandFilter, ...availableBrands.filter((brand) => !brandFilter.includes(brand))]
                          .filter((brand) => brand.toLowerCase().includes(brandSearch.toLowerCase()))
                          .map((brand) => (
                            <Checkbox
                              key={brand}
                              name="brands"
                              value={brand}
                              isChecked={brandFilter.includes(brand)}
                              onChange={(e) => updateSearchParam("pa_brand", brand, true)}
                            >
                              {brand}
                            </Checkbox>
                          ))
                      )}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              )}
              {/* Color Filter */}
              {availableColors.length > 0 && (
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">Χρώμα</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {availableColors.length > 5 && (
                      <Input
                        placeholder="Αναζήτηση..."
                        mb={4}
                        value={colorSearch}
                        onChange={(e) => setColorSearch(e.target.value)}
                      />
                    )}
                    <Stack maxHeight="150px" overflowY="auto">
                      {isLoading ? (
                        [...Array(5)].map((_, index) => (
                          <Skeleton key={index} height="20px" />
                        ))
                      ) : (
                        [...colorFilter, ...availableColors.filter((color) => !colorFilter.includes(color))]
                          .filter((color) => color.toLowerCase().includes(colorSearch.toLowerCase()))
                          .map((color) => (
                            <Checkbox
                              key={color}
                              name="colors"
                              value={color}
                              isChecked={colorFilter.includes(color)}
                              onChange={(e) => updateSearchParam("pa_color", color, true)}
                            >
                              {color}
                            </Checkbox>
                          ))
                      )}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              )}
              {/* Size Filter */}
              {availableSizes.length > 0 && (
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">Μέγεθος</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {availableSizes.length > 5 && (
                      <Input
                        placeholder="Αναζήτηση..."
                        mb={4}
                        value={sizeSearch}
                        onChange={(e) => setSizeSearch(e.target.value)}
                      />
                    )}
                    <Stack maxHeight="150px" overflowY="auto">
                      {isLoading ? (
                        [...Array(5)].map((_, index) => (
                          <Skeleton key={index} height="20px" />
                        ))
                      ) : (
                        [...sizeFilter, ...availableSizes.filter((size) => !sizeFilter.includes(size))]
                          .filter((size) => size.toLowerCase().includes(sizeSearch.toLowerCase()))
                          .map((size) => (
                            <Checkbox
                              key={size}
                              name="sizes"
                              value={size}
                              isChecked={sizeFilter.includes(size)}
                              onChange={(e) => updateSearchParam("pa_size", size, true)}
                            >
                              {size}
                            </Checkbox>
                          ))
                      )}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              )}
              {/* Price Filter */}
              <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Τιμή
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p="1">
                <Box p="2">
                  <Flex gap="1" alignItems="center">
                    <Input
                      size="sm"
                      width={24}
                      type="number"
                      placeholder={`Min (${minPrice || ""})`}
                      value={priceInput[0]} // Local state for min price
                      onChange={(e) => handleInputChange(0, e.target.value)}
                    />
                    <Text>-</Text>
                    <Input
                      size="sm"
                      width={24}
                      type="number"
                      placeholder={`Max (${maxPrice || ""})`}
                      value={priceInput[1]} // Local state for max price
                      onChange={(e) => handleInputChange(1, e.target.value)}
                    />
                  </Flex>
      
                  <Flex justifyContent="space-between" mt="4">
                    <Button size="sm" onClick={handleResetPrice}>
                      Reset
                    </Button>
                    <Button size="sm" colorScheme="pink" onClick={handleApplyPriceFilter}>
                      Apply
                    </Button>
                    
                  </Flex>
                </Box>
              </AccordionPanel>
              </AccordionItem>
            </Accordion>
            )}
          </DrawerBody>
          <DrawerFooter borderTopWidth='1px'>
            <Flex gap="4" justify="space-between" w="100%">
              <Flex justify="flex-start">
                < Button variant="outline" onClick={handleResetAll}>Καθαρισμός</Button>
              </Flex>
              <Flex justify="flex-end">
                <Button colorScheme="pink" onClick={handleDrawerClose}>Αποτελέσματα</Button>
              </Flex>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      
    </Box>
  );
};

export default BrandsPage;
