import React from "react";
import { Button, ButtonGroup, Box, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // Return empty pagination if totalPages is less than 1
  if (totalPages < 2) {
    return null; // Do not render anything
  }

  const getPageNumbers = () => {
    let pages = [];
    
    // Show first three pages, ellipsis, and last page if there are many pages
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, "...", totalPages];
      } else if (currentPage >= totalPages - 2) {
        pages = [1, "...", totalPages - 2, totalPages - 1, totalPages];
      } else {
        pages = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages];
      }
    }

    return pages;
  };

  const handlePageChange = (page) => {
    if (page === "...") return; // Ignore clicks on the ellipsis
    onPageChange(page);
  };

  const pageNumbers = getPageNumbers();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
      <ButtonGroup spacing={2}>
        {/* Previous Button */}
        <IconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon />}
          isDisabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        />

        {/* Page Numbers */}
        {pageNumbers.map((page, index) => (
          <Button
            key={index}
            onClick={() => handlePageChange(page)}
            isDisabled={page === "..."}
            variant={page === currentPage ? "solid" : "outline"}
            colorScheme={page === currentPage ? "pink" : "gray"}
          >
            {page}
          </Button>
        ))}

        {/* Next Button */}
        <IconButton
          aria-label="Next page"
          icon={<ChevronRightIcon />}
          isDisabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        />
      </ButtonGroup>
    </Box>
  );
};

export default Pagination;
