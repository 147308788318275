import React from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const SortAndViewControls = ({

  sortOrder,
  handleSortChange,
  pageSize,
  handleViewChange,
  filteredProducts,
}) => {
  return (
      <Flex gap={4}>
        {/* Sort By */}
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            size="sm"
            variant="outline"
          >
            <Text fontWeight="bold" display="inline">
              Ταξινόμηση:
            </Text>{" "}
            {sortOrder === "default"
              ? "Προεπιλογή"
              : sortOrder === "price-asc"
              ? "Αύξουσα τιμή"
              : "Φθίνουσα τιμή"}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleSortChange({ target: { value: "default" } })}>
              Προεπιλογή
            </MenuItem>
            <MenuItem onClick={() => handleSortChange({ target: { value: "price-asc" } })}>
              Αύξουσα τιμή
            </MenuItem>
            <MenuItem onClick={() => handleSortChange({ target: { value: "price-desc" } })}>
              Φθίνουσα τιμή
            </MenuItem>
          </MenuList>
        </Menu>

        {/* View */}
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            size="sm"
            variant="outline"
          >
            <Text fontWeight="bold" display="inline">
              Προβολή:
            </Text>{" "}
            {pageSize === -1
              ? "Όλα"
              : `${pageSize}`}
          </MenuButton>
          <MenuList>
          <MenuItem onClick={() => handleViewChange({ target: { value: 20 } })}>
              20 Προϊόντα ανά σελίδα
            </MenuItem>
            <MenuItem onClick={() => handleViewChange({ target: { value: 40 } })}>
              40 Προϊόντα ανά σελίδα
            </MenuItem>
            <MenuItem onClick={() => handleViewChange({ target: { value: 60 } })}>
              60 Προϊόντα ανά σελίδα
            </MenuItem>
            <MenuItem onClick={() => handleViewChange({ target: { value: -1 } })}>
              Όλα τα Προϊόντα
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
  );
};

export default SortAndViewControls;
