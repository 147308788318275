import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import logo from "../../assets/fe-logo.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Heading,
  Stack,
  Button,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import { InfoOutlineIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { FaTruck, FaStore } from "react-icons/fa";
import RelatedProductsGrid from "../common/RelatedProductsGrid";
import NotFound from "./NotFound";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

// URLs for remote data
const PRODUCTS_URL = "https://productfeed.fashion-editor.gr/product-feed/integration/products_data.json";
const CATEGORIES_URL = "https://productfeed.fashion-editor.gr/product-feed/integration/categories.json";

// Utility to build breadcrumb structure
const buildBreadcrumb = (slug, categories) => {
  const category = categories.find((cat) => cat.slug === slug);
  if (!category) return [];
  const parentBreadcrumb = category.parent
    ? buildBreadcrumb(categories.find((cat) => cat.id === category.parent)?.slug, categories)
    : [];
  return [...parentBreadcrumb, category];
};

const ProductDetailPage = () => {
  const { slug } = useParams(); // Get the product slug from the URL
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [isLoadingBreadcrumb, setIsLoadingBreadcrumb] = useState(true);
  const [product, setProduct] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState({
    sameCategoryAndColor: [],
    sameCategoryDifferentColor: [],
    sameBrandDifferentCategoryAndColor: []
  });

  // Fetch product and category data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch products
        const productsResponse = await fetch(PRODUCTS_URL);
        const productsData = await productsResponse.json();

        // Fetch categories
        const categoriesResponse = await fetch(CATEGORIES_URL);
        const categoriesData = await categoriesResponse.json();

        // Find the specific product
        const selectedProduct = productsData.find((item) => item.slug === slug);
        setProduct(selectedProduct);

        // Build breadcrumb
        if (selectedProduct) {
          const categorySlug = selectedProduct.taxonomies.product_cat_slug;
          const crumbs = buildBreadcrumb(categorySlug, categoriesData);
          setBreadcrumb(crumbs);
        }

        // Find related products
        if (selectedProduct) {
          const categorySlug = selectedProduct.taxonomies.product_cat_slug;
          const productColor = selectedProduct.taxonomies.pa_color?.[0] || ' ';
          const productBrand = selectedProduct.taxonomies.pa_brand?.[0] || ' ';

          const slug = selectedProduct.slug;
        
          let usedSlugs = new Set([slug]); // Start with the current product's slug

          // Products from the same category and color, excluding current product
          const sameCategoryAndColor = 
            productsData.filter((product) => 
              product.taxonomies.product_cat_slug?.includes(categorySlug) &&  
              product.taxonomies.pa_color?.includes(productColor) &&  
              !usedSlugs.has(product.slug)
            ).slice(0, 18);
          sameCategoryAndColor.forEach(product => usedSlugs.add(product.slug));

          // Products from the same category but different color, excluding duplicates
          const sameCategoryDifferentColor = 
            productsData.filter((product) => 
              product.taxonomies.product_cat_slug?.includes(categorySlug) &&  
              //!product.taxonomies.pa_color?.includes(productColor) &&  
              !usedSlugs.has(product.slug)
            ).slice(0, 18);
          sameCategoryDifferentColor.forEach(product => usedSlugs.add(product.slug));

          // Products from the same brand but different category and color, excluding duplicates
          const sameBrandDifferentCategoryAndColor = 
               productsData.filter((product) => product.taxonomies.pa_brand?.includes(productBrand) &&  !usedSlugs.has(product.slug)).slice(0, 18);
          sameBrandDifferentCategoryAndColor.forEach(product => usedSlugs.add(product.slug));
        
          // Setting related products with the filtered results
          setRelatedProducts({
            sameCategoryAndColor: sameCategoryAndColor.slice(0, 18),
            sameCategoryDifferentColor: sameCategoryDifferentColor.slice(0, 18),
            sameBrandDifferentCategoryAndColor: sameBrandDifferentCategoryAndColor.slice(0, 18)
          });

        }
        
      } catch (error) {
        console.error("Error fetching product or categories data:", error);
      } finally {
        setIsLoadingProduct(false);
        setIsLoadingBreadcrumb(false);
      }
    };

    fetchData();
    scrollToTop();
  }, [slug]);

  if (!isLoadingProduct && !product) {
    return <NotFound />;
  }
  //Function scrollToTop
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  //Add to Cart Button Labels
  const getButtonLabel = (programName) => {
    switch (programName) {
      case 'myshoe':
        return 'Myshoe.gr';
      case 'funkybuddha':
        return 'Funky-buddha.com';
      case 'notos':
        return 'Notos.gr';
      case 'pitsiriki':
        return 'Pitsiriki.gr';
      case 'central':
        return 'Centraleshop.gr';
      default:
        return ' ';
    }
  };
  //Programs Links
  const getProgramLink = (programName) => {
      switch (programName) {
        case 'myshoe':
          return 'https://go.linkwi.se/z/75-0/CD15770/?lnkurl=https://www.myshoe.gr/?Org=2927561&affiliate=linkwise&utm_source=linkwise&utm_medium=affiliates&utm_campaign=linkwise';
        case 'funkybuddha':
          return 'https://go.linkwi.se/z/12011-0/CD15770/?lnkurl=https://www.funky-buddha.com';
        case 'notos':
          return 'https://go.linkwi.se/z/12471-0/CD15770/?lnkurl=https://www.notos.gr';
        case 'pitsiriki':
          return 'https://go.linkwi.se/z/12345-0/CD15770/?lnkurl=https://www.pitsiriki.gr';
        case 'central':
          return 'https://go.linkwi.se/z/13628-0/CD15770/?lnkurl=https://www.centraleshop.gr';
        default:
          return ' ';
      }
  };

  // Generate JSON-LD for SEO
  const generateJsonLd = () => {
    const imageUrl = product?.images?.original?.url || "";
    const brandName = product?.taxonomies?.pa_brand || "";
    const priceCurrency = "EUR";

    const stripHtmlTags = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };

    const jsonLd = {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: product?.post_title || "",
      image: imageUrl,
      description: stripHtmlTags(product?.post_excerpt) || "",
      sku: product?.sku || "",
      brand: {
        "@type": "Brand",
        name: brandName,
      },
      offers: {
        "@type": "Offer",
        url: window.location.href,
        priceCurrency: priceCurrency,
        price: product?.price || "",
        itemCondition: "https://schema.org/NewCondition",
        availability: "https://schema.org/InStock",
        priceValidUntil: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0], // 30 days from now
        seller: {
          "@type": "Organization",
          name: "Fashion-editor.gr",
        },
      },
    };

    return <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>;
  };
  // Breadcrumb JSON-LD
  const generateBreadcrumbJsonLd = () => {
    if (!breadcrumb.length || !product) return null;

    const breadcrumbList = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumb
        .map((category, index) => ({
          "@type": "ListItem",
          position: index + 1,
          name: category.name,
          item: `${window.location.origin}/product-category/${category.slug}`,
        }))
        .concat({
          "@type": "ListItem",
          position: breadcrumb.length + 1,
          name: product.post_title,
          item: window.location.href,
        }),
    };

    return (
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbList)}
      </script>
    );
  };
  // WebSiteSearch JSON-LD
  const generateWebsiteSearchJsonLd = () => {
    const searchJsonLd = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "@id": `${window.location.origin}/#website`,
      url: window.location.origin,
      name: "Fashion-editor.gr",
      description:
        "Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr",
      potentialAction: [
        {
          "@type": "SearchAction",
          target: {
            "@type": "EntryPoint",
            urlTemplate: `${window.location.origin}/?s={search_term_string}`,
          },
          "query-input": {
            "@type": "PropertyValueSpecification",
            valueRequired: true,
            valueName: "search_term_string",
          },
        },
      ],
      inLanguage: "el",
    };

    return (
      <script type="application/ld+json">{JSON.stringify(searchJsonLd)}</script>
    );
  };


  return (
    <Box p="3" maxW="1320px" mx="auto" mb={-12}>
      <Helmet>
        {/* Title Tag */}
        <title>{product ? `${product.post_title} - Fashion-editor.gr` : 'Loading... - Fashion-editor.gr'}</title>

        {/* Meta Description */}
        <meta name="description" content={product ? `Απόκτησε τώρα ${product.post_title} online από το Fashion-editor.gr.`: 'Loading...'} />

        {/* Canonical URL */}
        <link rel="canonical" href={window.location.href} />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="product" />
        <meta property="og:title" content={product ? `${product.post_title}` : 'Loading...'} />
        <meta property="og:description" content={product ? `${product.post_excerpt}` : 'Loading...'} />
        <meta property="og:image" content={product?.images?.[0]?.src || logo}/>
        <meta property="og:url" content={window.location.href} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={product ? `${product.post_title}` : 'Loading...'} />
        <meta name="twitter:description" ontent={product ? `${product.post_excerpt}` : 'Loading...'} />
        <meta name="twitter:image" content={product?.images?.[0]?.src || logo}/>

        {generateJsonLd()}
        {generateBreadcrumbJsonLd()}
        {generateWebsiteSearchJsonLd()}
      </Helmet>

      {/* Breadcrumb */}
      {isLoadingBreadcrumb ? (
        <Skeleton height="20px" mb="6" />
      ) : (
        <Box mb="6" overflowX="auto" whiteSpace="nowrap">
          <Breadcrumb display="inline-block">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/">Αρχική</BreadcrumbLink>
            </BreadcrumbItem>
            {breadcrumb.map((crumb, index) => (
              <BreadcrumbItem key={crumb.id}>
                <BreadcrumbLink as={RouterLink} to={`/product-category/${crumb.slug}`}>
                  {crumb.name.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
            {product && (
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{product.post_title.replace(/&amp;/g, '&').replace(/AMP;/g, '')}</BreadcrumbLink>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </Box>
      )}

      {/* Main Product Info */}
      <Flex direction={['column', 'row']} gap="10%" w="100%" mb={20}>
        <Box flex={[null, '0.4']} w={['100%', '40%']}>
          {isLoadingProduct ? (
            <Skeleton height="300px" />
          ) : (
            <InnerImageZoom src={product.images?.original?.url || 'https://placehold.co/600x600'} zoomSrc={product.images?.original?.url || 'https://placehold.co/600x600'} />
          )}
        </Box>
        <Box flex={[null, '0.5']} w={['100%', '50%']}>
          <Stack spacing="4">
            {isLoadingProduct ? (
              <>
                <Skeleton height="30px" />
                <SkeletonText mt="4" noOfLines={4} spacing="4" />
                <Skeleton height="20px" width="100px" />
              </>
            ) : (
              <>
                {product.taxonomies.pa_brand ?  <RouterLink to={`/brands/${product.taxonomies?.pa_brand}`}><Text size="xs">{product.taxonomies.pa_brand} </Text></RouterLink>: ''}
                <Heading as="h1" size="md">{product.post_title.replace(/&amp;/g, '&').replace(/AMP;/g, '')}</Heading>
                <Text fontSize="xl" mt={5}>
                  {product?.sale_price && product?.regular_price &&
                  product.sale_price.trim() !== '' && product.regular_price.trim() !== '' ? (
                    parseFloat(product.sale_price) < parseFloat(product.regular_price) ? (
                      <>
                        <span style={{ textDecoration: 'line-through', color: 'red' }}>
                          {product.regular_price} €
                        </span>{' '}
                        <span>{product.sale_price} €</span>
                      </>
                    ) : (
                      <span>{product.regular_price} €</span>
                    )
                  ) : (
                    <span>{product.price} €</span>
                  )}
                </Text>

        
                {/* Available Product Sizes */}
                <Stack mt={5} direction="row" spacing={2} align="center" wrap="wrap">
                  {product.taxonomies?.pa_size?.map(size => (
                      <Button key={size} variant="outline" size="sm">
                          {size}
                      </Button>
                  ))}
                </Stack>
                
                {/* Add to Cart Button */}
                {product.external_url && (
                  <Button mt={5} mb={10} as="a" href={product.external_url} target="_blank" colorScheme="pink" rightIcon={<ExternalLinkIcon/>} >
                    ΑΠΟΚΤΗΣΕ ΤΟ ΣΤΟ {getButtonLabel(product.taxonomies?.program_name)}
                  </Button>
                )}

                {/* Additional Information Section */}
                <Accordion allowMultiple>
                    {product.taxonomies.program_name && (
                      <AccordionItem>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <FaStore style={{ marginRight: '13px', verticalAlign: 'middle', display: 'inline-flex', marginBottom: '3px', }}/> 
                            <a href={getProgramLink(product.taxonomies?.program_name)} target="_blank" rel="noopener noreferrer">
                              <Text as="span">{getButtonLabel(product.taxonomies?.program_name)} </Text>
                            </a>
                            <ExternalLinkIcon style={{ marginLeft: '2px', verticalAlign: 'middle', display: 'inline-flex', marginBottom: '3px', }}/> 
                          </Box>
                        </AccordionButton>
                      </AccordionItem>
                    )}
                    {product.taxonomies.product_availability && (
                      <AccordionItem>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <FaTruck style={{ marginRight: '13px', verticalAlign: 'middle', display: 'inline-flex', marginBottom: '3px', }}/> 
                            <Text as="span">{product.taxonomies?.product_availability}</Text>
                          </Box>
                        </AccordionButton>
                      </AccordionItem>
                    )}
                    <AccordionItem>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <InfoOutlineIcon style={{ marginRight: '8px', verticalAlign: 'middle' }}/> Λεπτομέρειες προϊόντος 
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    
                    <AccordionPanel p="1">
                      <Box p="2">
                        <Text dangerouslySetInnerHTML={{ __html: product.post_excerpt }} />
                      </Box>
                      <Box p="2">
                        <Stack spacing="4">
                          {product.sku ? <Text><strong>SKU:</strong> {product.sku} </Text>: ''}
                          {product.taxonomies.pa_brand ? <Text><strong>Μάρκα:</strong> {product.taxonomies.pa_brand} </Text>: ''}
                          {product.taxonomies.pa_size && product.taxonomies.pa_size.length > 0 ? <Text><strong>Μέγεθος:</strong> {product.taxonomies.pa_size.join(', ')} </Text>: ''}
                          {product.taxonomies.pa_color && product.taxonomies.pa_color.length > 0 ? <Text><strong>Χρώμα:</strong> {product.taxonomies.pa_color.join(', ')} </Text>: ''}
                        </Stack>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </>
            )}
          </Stack>
        </Box>
      </Flex>

      {/* Related Products */}
      <Box
        bg="gray.100" // Background color for related products
        w="100vw" // Full width
        position="relative"
        left="50%"
        right="50%"
        marginLeft="-50vw"
        marginRight="-50vw"
        py="6"
      >
        <RelatedProductsGrid 
          isLoading={isLoadingProduct} 
          header="Παρόμοια Προϊόντα" 
          products={relatedProducts.sameCategoryAndColor}
        />
        
        {product?.taxonomies?.product_cat && (
          <RelatedProductsGrid 
            isLoading={isLoadingProduct} 
            header={`${product.taxonomies.product_cat} που ίσως σε ενδιαφέρουν`} 
            products={relatedProducts.sameCategoryDifferentColor}
          />
        )}
        
        {product?.taxonomies?.pa_brand && (
          <RelatedProductsGrid 
            isLoading={isLoadingProduct} 
            header={`Οι καλύτερες επιλογές για σένα από την ${product.taxonomies.pa_brand}`}  
            products={relatedProducts.sameBrandDifferentCategoryAndColor}
          />
        )}

      </Box>
    </Box>
  );
};

export default ProductDetailPage;
