import React from 'react';
import { Box, Image, Text, Stack, Skeleton, SkeletonText, Heading, SimpleGrid } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const RelatedProductsGrid = ({ products, header, isLoading }) => {
  return (

      <Box mt="8" maxW="1320px" mx="auto" p={2}>
        {isLoading ? (
          <Skeleton height="30px" width="200px" mb="6" />
        ) : (
          products.length > 0 ? <Heading textAlign={['center', 'left']} size="lg" mb="4">{header}</Heading> : ''
        )}

        {isLoading ? (
          <SimpleGrid columns={[2, 4]} spacing="40px">
            {[...Array(4)].map((_, index) => (
              <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
                <Skeleton height="200px" />
                <Box p="6">
                  <SkeletonText mt="4" noOfLines={2} spacing="4" />
                  <Skeleton height="40px" width="100px" mt="4" />
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        ) : (
          <SimpleGrid columns={[2, 4, 6]} spacing="2">
            {products.map(relatedProduct => (
              <Box key={relatedProduct.objectID} borderWidth="1px" borderRadius="lg" overflow="hidden" bg="white">
                <RouterLink to={`/product/${relatedProduct.slug}`}>
                  <Image
                    src={relatedProduct.images?.thumbnail?.url}
                    alt={relatedProduct.post_title.replace(/&amp;/g, '&').replace(/AMP;/g, '')}
                    width="100%"
                    height="auto"
                    objectFit="cover"
                    loading="lazy"
                    _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s' }}
                  />
                </RouterLink>
                <Box p="3">
                  <Stack spacing="3">
                    {relatedProduct.taxonomies.product_cat && (
                      <Text fontSize="xs" textTransform="uppercase">
                        {relatedProduct.taxonomies.product_cat[0].toLowerCase().replace(/&amp;/g, '&').replace(/AMP;/g, '')}
                      </Text>
                    )}
                    <RouterLink to={`/product/${relatedProduct.slug}`}>
                      <Text fontWeight="bold" fontSize="sm" noOfLines={2} h="3em" lineHeight="1.5em">{relatedProduct.post_title.replace(/&amp;/g, '&').replace(/AMP;/g, '')}</Text>
                    </RouterLink>
                    <Text fontSize="md">
                      {parseFloat(relatedProduct.sale_price) < parseFloat(relatedProduct.regular_price) ? (
                        <>
                          <span style={{ textDecoration: 'line-through', color: 'red' }}>
                            {relatedProduct.regular_price} €
                          </span>{' '}
                          <span>{relatedProduct.sale_price} €</span>
                        </>
                      ) : (
                        <span>{relatedProduct.regular_price} €</span>
                      )}
                    </Text>
                  </Stack>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        )}
      </Box>
      );
    };
    
    export default RelatedProductsGrid;
    