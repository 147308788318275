import React from 'react';
import { Box, Image, Text, Stack, HStack, Skeleton, SkeletonText, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const FeaturedProductCarousel = ({ products, isLoading }) => {
  const imageHeight = "220px"; // Define a consistent height for all images

  return (
    <HStack overflowX="scroll" spacing="24px">
      {isLoading ? (
        [...Array(5)].map((_, index) => (
          <Box 
            key={index} 
            minWidth="300px" 
            borderWidth="1px" 
            borderRadius="lg" 
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <Skeleton height={imageHeight} />
            <Box p="6" flex="1" display="flex" flexDirection="column" justifyContent="space-between">
              <Stack spacing="3">
                <SkeletonText mt="4" mb="4" noOfLines={2} />
              </Stack>
              <Skeleton height="40px" width="100px" mt="auto" />
            </Box>
          </Box>
        ))
      ) : (
        products.map((product, index) => (
          <Box 
            key={index}
            minWidth="220px" 
            borderWidth="1px" 
            borderRadius="lg" 
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <ChakraLink as={RouterLink} to={`/product/${product.slug}`} flex="1">
              <Image
                src={product.images?.thumbnail?.url}
                alt={product.post_title}
                width="100%"
                height={imageHeight} // Set fixed height for uniformity
                objectFit="cover" // Ensure the image covers the area, cropping if necessary
                loading="lazy"
                _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s' }}
              />
            </ChakraLink>
            <Box p="3" flex="1" display="flex" flexDirection="column" justifyContent="space-between">
              <Stack spacing="3">
                <Text fontSize="xs" noOfLines={2} h="3em" lineHeight="1.5em" textTransform="uppercase">
                    {product.taxonomies.product_cat?.map((cat) => cat.replace(/&amp;/g, '&').replace(/AMP;/g, ''))}
                </Text>
                <Text fontWeight="bold" fontSize="sm" noOfLines={2} h="3em" lineHeight="1.5em">{product.post_title}</Text>
                <Text fontSize="md">
                  {parseFloat(product.sale_price) < parseFloat(product.regular_price) ? (
                    <>
                      <span style={{ textDecoration: 'line-through', color: 'red' }}>
                        {product.regular_price} €
                      </span>{' '}
                      <span>{product.sale_price} €</span>
                    </>
                  ) : (
                    <span>{product.regular_price} €</span>
                  )}
                </Text>
              </Stack>
            </Box>
          </Box>
        ))
      )}
    </HStack>
  );
};

export default FeaturedProductCarousel;
