import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  HStack,
  Flex,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  useDisclosure,
  List,
  ListItem,
  Portal,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { HamburgerIcon, SearchIcon, CloseIcon } from "@chakra-ui/icons";
import HeaderNavigation from "./HeaderNavigation";
import logo from "../../assets/fe-logo.png";

// URL for fetching product data
const PRODUCTS_URL =
  "https://productfeed.fashion-editor.gr/product-feed/integration/products_data.json";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Control the drawer state
  const [isSearchOpen, setIsSearchOpen] = useState(false); // Control the search input visibility
  const [searchQuery, setSearchQuery] = useState(""); // State for search input value
  const [suggestions, setSuggestions] = useState([]); // State for search suggestions
  const [showSuggestions, setShowSuggestions] = useState(false); // Control suggestions dropdown visibility
  const [products, setProducts] = useState([]); // State to store fetched product data
  const navigate = useNavigate(); // Navigation hook for redirecting to search results

  // Fetch product data from the remote URL
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(PRODUCTS_URL);
        const data = await response.json();
        setProducts(data);       
      } catch (error) {
        console.error("Error fetching products:", error);     
      }
    };

    fetchProducts();
  }, []);

  // Filter suggestions based on search query
  const fetchSearchSuggestions = (query) => {
    if (query.length < 3) {
      setSuggestions([]); // Clear suggestions if query is too short
      return;
    }

    // Filter products from the fetched data
    const filteredSuggestions = products.filter((product) => {
      const titleMatch = product.post_title
        .toLowerCase()
        .includes(query.toLowerCase());
      const categoryMatch = product.taxonomies?.product_cat?.some((cat) =>
        cat.toLowerCase().includes(query.toLowerCase())
      );
      return titleMatch || categoryMatch;
    });

    setSuggestions(filteredSuggestions.slice(0, 5)); // Limit to 5 suggestions
    setShowSuggestions(true);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    if (value.length >= 3) {
      fetchSearchSuggestions(value); // Fetch suggestions when input has 3 or more characters
    } else {
      setSuggestions([]); // Clear suggestions if input has fewer than 3 characters
      setShowSuggestions(false);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    if (searchQuery.trim() !== "") {
      navigate(`/search?query=${searchQuery}`);
      setSearchQuery(""); // Clear the input after search
      setIsSearchOpen(false); // Close the search input after search
      setShowSuggestions(false); // Hide suggestions after search
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.post_title); // Fill the input with the selected suggestion
    setShowSuggestions(false); // Hide the suggestions dropdown
    navigate(`/search?query=${suggestion.post_title}`); // Redirect to search results
  };

  const clearSearch = () => {
    setSearchQuery(""); // Clear the input
    setShowSuggestions(false); // Hide suggestions
  };

  // Toggle the mobile search input
  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  return (
    <Box bg="pink.500" boxShadow="md">
      {/* Desktop Layout */}
      <HStack
        spacing="24px"
        alignItems="center"
        display={{ base: "none", md: "flex" }}
      >
        {/* Logo Section */}
        <Box bg="white" padding="4" w="210px" h="80px">
          <Flex alignItems="center" justifyContent="center" h="100%">
            <RouterLink to="/">
              <Image src={logo} alt="Fashion-editor.gr" height="50px" />
            </RouterLink>
          </Flex>
        </Box>

        {/* Navigation and Search Section */}
        <Box bg="pink.500" padding="2" color="white" w="100%" h="80px">
          <Flex alignItems="center" justifyContent="space-between" h="100%">
            {/* Desktop Navigation */}
            <HeaderNavigation />

            {/* Search Bar */}
            <form
              id="product-search-form"
              role="search"
              onSubmit={handleSearchSubmit}
              style={{ position: "relative" }}
            >
              <InputGroup>
                {searchQuery && (
                  <InputLeftElement>
                    <IconButton
                      aria-label="Clear search"
                      icon={<CloseIcon />}
                      onClick={clearSearch}
                      variant="ghost"
                      color="gray.500"
                    />
                  </InputLeftElement>
                )}
                <Input
                  placeholder="Search..."
                  bg="white"
                  width="100%"
                  color="black"
                  _placeholder={{ color: "gray.500" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onFocus={() => setShowSuggestions(true)}
                />
                <InputRightElement>
                  <IconButton
                    aria-label="Search"
                    icon={<SearchIcon />}
                    type="submit"
                    variant="ghost"
                    color="gray.500"
                  />
                </InputRightElement>
              </InputGroup>

              {/* Autosuggest Dropdown */}
              {showSuggestions && suggestions.length > 0 && (
                <Portal>
                  <Box
                    bg="white"
                    color="black"
                    mt="2"
                    borderRadius="md"
                    boxShadow="lg"
                    position="absolute"
                    top={{ base: "100px", md: "55px" }}
                    right={{ base: "0px", md: "8px" }}
                    width={{ base: "100%", md: "400px" }}
                    zIndex="10"
                    border="1px solid #ddd"
                  >
                    <List spacing={2}>
                      {suggestions.map((suggestion) => (
                        <ListItem
                          key={suggestion.id}
                          p="2"
                          display="flex"
                          alignItems="center"
                          borderBottom="1px solid #eee"
                          _hover={{ backgroundColor: "gray.100" }}
                          onMouseDown={() => handleSuggestionClick(suggestion)}
                          cursor="pointer"
                        >
                          {/* Thumbnail */}
                          <Box flexShrink={0} mr={3}>
                            <Image
                              src={suggestion.images?.thumbnail?.url}
                              alt={suggestion.post_title}
                              boxSize="50px"
                              objectFit="cover"
                              borderRadius="md"
                            />
                          </Box>

                          {/* Title and Price */}
                          <Box flex="1">
                            <Text
                              fontSize="sm"
                              fontWeight="bold"
                              noOfLines={1}
                            >
                              {suggestion.post_title}
                            </Text>
                            <Text fontSize="sm" color="gray.600">
                              {parseFloat(suggestion.sale_price) <
                              parseFloat(suggestion.regular_price) ? (
                                <>
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      color: "red",
                                    }}
                                  >
                                    {suggestion.regular_price} €
                                  </span>{" "}
                                  <span>{suggestion.sale_price} €</span>
                                </>
                              ) : (
                                <span>{suggestion.regular_price} €</span>
                              )}
                            </Text>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Portal>
              )}
            </form>
          </Flex>
        </Box>
      </HStack>

      {/* Mobile Layout */}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding="2"
        display={{ base: "flex", md: "none" }}
        bg="pink.500"
        color="white"
      >
        <IconButton
          aria-label="Menu"
          icon={<HamburgerIcon />}
          variant="outline"
          color="white"
          border="none"
          onClick={onOpen}
        />

        <RouterLink to="/">
          <Image
            src={logo}
            alt="Fashion-editor.gr"
            height="40px"
            bg="white"
            padding="4px"
          />
        </RouterLink>

        <IconButton
          aria-label="Search"
          icon={isSearchOpen ? <CloseIcon /> : <SearchIcon />}
          variant="outline"
          color="white"
          border="none"
          onClick={toggleSearch}
        />
      </Flex>

      {/* Mobile Search Input */}
      {isSearchOpen && (
        <Box bg="pink.500" padding="2" display={{ base: "block", md: "none" }}>
          <form onSubmit={handleSearchSubmit}>
            <InputGroup>
              {searchQuery && (
                <InputLeftElement>
                  <IconButton
                    aria-label="Clear search"
                    icon={<CloseIcon />}
                    onClick={clearSearch}
                    variant="ghost"
                    color="gray.500"
                  />
                </InputLeftElement>
              )}
              <Input
                placeholder="Search..."
                bg="white"
                color="black"
                _placeholder={{ color: "gray.500" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <InputRightElement>
                <IconButton
                  aria-label="Search"
                  icon={<SearchIcon />}
                  type="submit"
                  variant="ghost"
                  color="gray.500"
                />
              </InputRightElement>
            </InputGroup>
          </form>
        </Box>
      )}

      {/* Mobile Menu Drawer */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="white" />
          <DrawerBody bg="pink.700" padding="4" color="white">
            {/* Mobile Navigation */}
            <Box mt={10}>
              <HeaderNavigation isMobile={true} onClose={onClose} />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
