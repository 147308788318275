import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import CookieConsent from "react-cookie-consent"; // Import the cookie consent component
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import HomePage from './components/pages/HomePage';
import CategoryPage from './components/pages/CategoryPage';
import BrandsPage from './components/pages/BrandsPage';
import SearchPage from './components/pages/SearchPage';
import ProductDetailPage from './components/pages/ProductDetailPage';
import FAQ from './components/pages/FAQ';
import TermsAndConditions from './components/pages/TermsAndConditions';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import NotFound from './components/pages/NotFound';

// Track page views when the route changes
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview for the current path
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}

function App() {
  // Initialize Google Analytics once on app load
  useEffect(() => {
    ReactGA.initialize('G-1CDJTNX8NN'); // Replace with your Measurement ID
  }, []);

  // Track each pageview
  usePageTracking();

  return (
    <HelmetProvider>
        <Box>
          <Header />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/product-category/:categorySlug" element={<CategoryPage />} />
              <Route path="/brands/:brandSlug" element={<BrandsPage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/product/:slug" element={<ProductDetailPage />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
            </Routes>
          <Footer />
        </Box>
      {/* Add Cookie Consent Popup */}
      <CookieConsent
        location="bottom"
        buttonText="Αποδοχή"
        cookieName="FECookieConsent"
        style={{ background: "grey" }}
        buttonStyle={{ color: "black", fontSize: "13px" }}
        expires={150}
      >
        Στο Fashion-editor.gr χρησιμοποιούμε εργαλεία όπως τα cookies για να σου προσφέρουμε μία κορυφαία προσωποποιημένη εμπειρία, για να σε βοηθήσουμε να βρεις ευκολότερα αυτό που ψάχνεις, καθώς και για την ανάλυση της επισκεψιμότητάς μας.
        <a href="/privacy-policy" style={{ color: "#fff", textDecoration: "underline" }}>
          Μάθε Περισσότερα
        </a>
      </CookieConsent>
    </HelmetProvider>
  );
}

export default App;
