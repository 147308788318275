// src/components/HeroBanner.js
import React from 'react';
import { Box, Text, Button, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import banner from '../../assets/hero-banner-updated.webp'; // Import the banner image

const HeroBanner = () => {
  return (
    <Box
      width="100vw"  // Full width of the viewport
      height={{ base: '65vh', md: '50vw' }} // Adjust height based on screen size
      bgImage={`url(${banner})`} // Reference the imported banner image
      bgPosition="center" // Center the background image
      bgRepeat="no-repeat" // Do not repeat the background image
      bgSize="cover" // Cover the entire box area with the background image
      display="flex" // Flexbox to align children elements
      alignItems="center" // Vertically center the children
      justifyContent="center" // Horizontally center the children
      color="white" // Text color
      position="relative" // Position the box relative to its normal position
      left="50%" // Offset the box to the center
      transform="translateX(-50%)" // Correct the offset to perfectly align with the viewport
      mx="auto" // Ensure it's centered horizontally in the container
    >
   
      <Box
        textAlign="center"
        bg="white" // White background
        opacity="0.9" // 90% opacity
        p={{ base: '4', md: '8' }} // Adjust padding for mobile and larger screens
        borderRadius="md" // Rounded corners for the box
        maxW={{ base: '90%', md: 'lg' }} // Adjust width for mobile and larger screens
        mx="auto" // Center the box horizontally
      >
        <Text fontSize={{ base: '2xl', md: '4xl' }} fontWeight="bold" color="black">
          Καλωσήρθατε στο Fashion-editor.gr
        </Text>
        <Text fontSize={{ base: 'lg', md: 'xl' }} mt="4" color="black">
          Ανακαλύψτε το στυλ που σας εκφράζει
        </Text>
        
        <Stack direction='row' spacing={4} mt="8" justify="center">
          <Link to="/product-category/andras">
            <Button colorScheme="pink" size={{ base: 'md', md: 'lg' }}>ΑΝΔΡΑΣ</Button>
          </Link>
          <Link to="/product-category/gynaika">
            <Button colorScheme="pink" size={{ base: 'md', md: 'lg' }}>ΓΥΝΑΙΚΑ</Button>
          </Link>
          <Link to="/product-category/paidi">
            <Button colorScheme="pink" size={{ base: 'md', md: 'lg' }}>ΠΑΙΔΙ</Button>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};

export default HeroBanner;
