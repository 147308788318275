// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated to use 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import ErrorBoundary from './components/layout/ErrorBoundary';

const container = document.getElementById('root'); // Get the root element
const root = ReactDOM.createRoot(container); // Create a root

// Create a new Query Client
// const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Router>
      <ChakraProvider>
        {/* <QueryClientProvider client={queryClient}> */}
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
        {/* </QueryClientProvider> */}
      </ChakraProvider>
    </Router>
  </React.StrictMode>
);
